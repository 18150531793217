import React, { useEffect, createRef } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import UpdateLinks from "../components/UpdateLinks";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Navbar from "../components/Navbar";

export const PrivacyPolicyPageTemplate = ({
  title,
  content,
  contentComponent,
  helmet,
}) => {
  const PageContent = contentComponent || Content;
  const terms = createRef();
  
	useEffect(()=>{
  	fetch("https://app.termageddon.com/api/policy/WW1SME1TOVVkVTF6U1VGWmIwRTlQUT09?no-title=true")
      .then(res => res.text())
      .then(res => terms.current.innerHTML = res);
  },[]);

  return (
    <div className="is-relative">
      <Navbar />
      <section className="section section--gradient">
        {helmet || ""}
        <UpdateLinks />
        <div className="p-5b p-4-mobile has-background-light">
          <div className="container ml-0 mr-auto pt-5b">
            <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="content" ref={terms}></div>
            <PageContent className="content" content={content} />
          </div>
        </div>
      </section>
    </div>
  );
};

PrivacyPolicyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PrivacyPolicyPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <PrivacyPolicyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        helmet={
          <Helmet titleTemplate="%s | Smart About Water">
            {/* TO DO: add site title to template using query */}
            <title>{`${post.frontmatter.seoTitle}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPolicyPage;

export const privacyPolicyPageQuery = graphql`
  query PrivacyPolicyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seoTitle
        description
      }
    }
  }
`;
